import { COMMENTS_ORDER } from "../../../../../constants/BrandedCommunity/Comments";
import { ICommentData } from "../../../../../components/BrandedCommunity/Post/components/Comment/Comment.types";
import { IUseCommentsList } from "./useCommentsList.types";
import { useState } from "react";
import { useToggleState } from "../../../../common/dataTypes/boolean";
import { checkIsFunction } from "../../../../common/dataTypes/function";
import { getErrorMessageFromApiError } from "../../../../common/api";

const page_sizes = {
  first_call: 3,
  subsequent_calls: 10,
};

export const useCommentsList = ({
  commentsOrder,
  userUuid,
  isCreator,
  callSubmitApi,
  onSubmitSuccess,
  callGetCommentsApi,
  callLikeApi,
  callEditApi,
  callDeleteApi,
  notifySuccess,
  notifyError,
}: IUseCommentsList.IUseCommentsListProps): IUseCommentsList.IUseCommentsListReturn => {
  const [isLoading, startLoading, stopLoading] = useToggleState(false);
  const [totalComments, setTotalComments] = useState<number | undefined>(
    undefined,
  );
  const [data, setData] = useState<Array<ICommentData>>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(page_sizes.first_call);

  const loadComments = (args: { successCallback?: () => void } = {}) => {
    if (isLoading) return;
    startLoading();

    // initial
    let newPage = 0;
    let newPageSize = page_sizes.first_call;
    if (page === 0) {
      // fetch first page with page size 3
      newPage = 1;
      newPageSize = page_sizes.first_call;
    } else if (page === 1 && pageSize === page_sizes.first_call) {
      // fetch first page with page size 10
      newPage = 1;
      newPageSize = page_sizes.subsequent_calls;
    } else {
      newPage = page + 1;
      newPageSize = page_sizes.subsequent_calls;
    }

    callGetCommentsApi({ page: newPage, page_size: newPageSize })
      .then((response) => {
        const responseData = response?.data || {};
        const newData: Array<ICommentData> = responseData.comments || [];
        const newTotalComments: number = responseData.total || 0;
        setPageSize(newPageSize);
        setTotalComments(newTotalComments);
        if (newPage === 1) {
          setData([...newData]);
        } else {
          setData([...data, ...newData]);
        }
        if (args.successCallback) args.successCallback();
        setPage(newPage);
      })
      .catch((e) => {
        notifyError(
          getErrorMessageFromApiError(e) ||
            "An error occured, please try after some time",
        );
      })
      .finally(stopLoading);
  };

  const onSubmit: IUseCommentsList.IUseCommentsListReturn["onSubmit"] = ({
    comment_body,
    successCallback,
  }) => {
    if (isLoading) return;

    const processedCommentBody = (comment_body || "").trim();
    if (!processedCommentBody) {
      notifyError("Comment cannot be empty!");
      return;
    }

    startLoading();

    callSubmitApi({ comment: processedCommentBody })
      .then(() => {
        // COMMENTS
        // refetch page 1
        if (commentsOrder === COMMENTS_ORDER.DESCENDING) {
          // initial
          let newPage = 0;
          let newPageSize = page_sizes.first_call;
          if (data.length < page_sizes.first_call) {
            // fetch first page with page size 3
            newPage = 1;
            newPageSize = page_sizes.first_call;
          } else {
            // fetch first page with page size 10
            newPage = 1;
            newPageSize = page_sizes.subsequent_calls;
          }

          callGetCommentsApi({
            page: newPage,
            page_size: newPageSize,
          })
            .then((response) => {
              const responseData = response?.data || {};
              const newData: Array<ICommentData> = responseData.comments || [];
              const newTotalComments: number = responseData.total || 0;
              setPageSize(newPageSize);
              setTotalComments(newTotalComments);
              setData([...newData]);
              setPage(newPage);
            })
            .finally(stopLoading);
        } else {
          // REPLIES
          // refetch current page
          /**
           * TODO: 2 replies list
           *  1. loaded
           *  2. posted
           * on load more, remove items from posted array that are there in newly loaded 10 items
           */
          let newPage = page;
          if (newPage === 0) {
            newPage = 1;
          }
          callGetCommentsApi({
            page: newPage,
            page_size: pageSize,
          })
            .then((response) => {
              const responseData = response?.data || {};
              const newDataInResponse: Array<ICommentData> =
                responseData.comments || [];
              const newTotalComments: number = responseData.total || 0;
              setTotalComments(newTotalComments);
              if (newPage === 1) {
                setData([...newDataInResponse]);
              } else {
                let newData: ICommentData[] = [];
                const firstCommentUuidInDataArg = newDataInResponse[0]?.uuid;
                const indexToBeReplacedFrom = data.findLastIndex(
                  (i) => i.uuid === firstCommentUuidInDataArg,
                );
                if (indexToBeReplacedFrom >= 0) {
                  newData = [
                    ...data.slice(0, indexToBeReplacedFrom),
                    ...newDataInResponse,
                  ];
                }
                setData(newData);
              }
            })
            .finally(stopLoading);
        }

        if (onSubmitSuccess && checkIsFunction(onSubmitSuccess))
          onSubmitSuccess();

        if (successCallback && checkIsFunction(successCallback))
          successCallback();
      })
      .catch((e) => {
        notifyError(
          getErrorMessageFromApiError(e) ||
            "An error occured, please try after some time",
        );
        stopLoading();
      });
  };

  const [isProcessingComment, startProcessingComment, stopProcessingComment] =
    useToggleState();

  const onLikeClick: IUseCommentsList.IUseCommentsListReturn["onLikeClick"] = ({
    comment_uuid,
  }) => {
    if (isProcessingComment) return;
    startProcessingComment();
    callLikeApi({ comment_uuid })
      .then((response) => {
        const hasLikedComment = !!response?.data?.has_liked;
        const newData = data.map((i) => {
          if (i.uuid !== comment_uuid) {
            return i;
          }

          let newLikesCount = i.likes_count;
          if (hasLikedComment !== !!i.has_liked_comment) {
            newLikesCount = hasLikedComment
              ? i.likes_count + 1
              : i.likes_count - 1;
          }
          return {
            ...i,
            has_liked_comment: hasLikedComment,
            likes_count: newLikesCount,
          };
        });
        setData(newData);
      })
      .catch((e) => {
        notifyError(
          getErrorMessageFromApiError(e) ||
            "An error occured, please try after some time",
        );
      })
      .finally(stopProcessingComment);
  };

  const onUpdate: IUseCommentsList.IUseCommentsListReturn["onUpdate"] = ({
    commentData,
    successCallback,
  }) => {
    if (!(userUuid === commentData.commenter_username)) return;
    if (isProcessingComment) return;

    const processedCommentBody = (commentData.comment || "").trim();
    if (!processedCommentBody) {
      notifyError("Comment cannot be empty!");
      return;
    }

    startProcessingComment();
    callEditApi({
      comment_uuid: commentData.uuid,
      updated_values: { comment: processedCommentBody },
    })
      .then(() => {
        const newData = [...data].map((i) => {
          if (i.uuid !== commentData.uuid) {
            return i;
          }
          return { ...i, comment: processedCommentBody };
        });
        setData(newData);
        successCallback();
        notifySuccess("Updated!");
      })
      .catch((e) => {
        notifyError(
          getErrorMessageFromApiError(e) ||
            "An error occured, please try after some time",
        );
      })
      .finally(stopProcessingComment);
  };

  const onDelete: IUseCommentsList.IUseCommentsListReturn["onDelete"] = ({
    commentData,
  }) => {
    if (
      !(
        isCreator || // creator can delete any comment
        userUuid === commentData.commenter_username
      )
    )
      return;
    if (isProcessingComment) return;
    startProcessingComment();
    callDeleteApi({ comment_uuid: commentData.uuid })
      .then(() => {
        notifySuccess("Deleted!");
        const newData = [...data].filter((i) => i.uuid !== commentData.uuid);
        setData(newData);
        if (totalComments) {
          setTotalComments(totalComments - 1);
        }
      })
      .catch((e) => {
        notifyError(
          getErrorMessageFromApiError(e) ||
            "An error occured, please try after some time",
        );
      })
      .finally(stopProcessingComment);
  };

  return {
    onSubmit,
    data,
    userUuid,
    isCreator,
    totalComments,
    showLoadMoreCommentsBtn: data.length < (totalComments ?? 0),
    loadComments,
    onLikeClick,
    onUpdate,
    onDelete,
  };
};
